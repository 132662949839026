import { createReducer, EntityState } from '@reduxjs/toolkit';

import { Issue, IssueStatus } from '@domain/Issues/models/Issue';
import { IConcise, IPagingMeta } from '@repo/shared/types';
import { issuesActions } from '@application/Issues/store/issuesActions';
import {
  CompletedIssuesLateStatus,
  IssuesFilters,
  PendingIssuesDueDateStatus,
} from '@application/Issues/models/IssuesFilters';
import {
  issueEventsEntityAdapter,
  issuesEntityAdapter,
} from '@application/Issues/store/entityAdapters';
import { dateUTC, getInitialFilters } from '@utils';
import { IssuesPage } from '@application/Issues/enums/IssuesPage';
import { AssignmentType } from '@repo/shared/enums';
import { DeleteModalState } from '@application/IssueTypes/models/DeleteModalState';
import { IssueEvent } from '@domain/Issues/models/IssueEvent';
import { IssueQuestion } from '@domain/Issues/models/IssueQuestion';
import { AssignUsersToIssuesModalState } from '@application/Issues/models/AssignUsersToIssuesModalState';
import { IssueContext } from '@domain/Issues/models/IssueContext';

export function getInitialIssuesFilters(): IssuesFilters {
  return {
    ...getInitialFilters(),
    orderBy: 'dueDate',
    priority: null,
    assignment: AssignmentType.all,
    assignedTo: null,
    assignedToGroup: null,
    startDate: null,
    endDate: null,
    auditObjectIds: [],
    issueTypeIds: [],
    reason: null,
    dueDateStatus: PendingIssuesDueDateStatus.All,
    lateStatus: CompletedIssuesLateStatus.All,
  };
}

const initialIssuesFilters = getInitialIssuesFilters();

const issuesListInitialState = {
  loading: false,
  data: issuesEntityAdapter.getInitialState(),
  meta: null,
  error: null,
  page: IssuesPage.Pending,
  showFiltersModal: false,
  showCreateIssueModal: false,
  filters: {
    [IssuesPage.My]: { ...initialIssuesFilters },
    [IssuesPage.Pending]: { ...initialIssuesFilters },
    [IssuesPage.Archive]: { ...initialIssuesFilters },
  },
};

const issueDetailsInitialState = {
  id: null,
  loading: false,
  error: null,
  issue: null,
  questions: [],
  audits: [],
  correctiveActions: [],
  events: {
    loading: false,
    error: null,
    meta: null,
    data: issueEventsEntityAdapter.getInitialState(),
    lastUpdateDateTimeUtc: null,
  },
};

export const issuesReducer = createReducer<{
  issuesList: {
    loading: boolean;
    data: EntityState<Issue, string>;
    meta: IPagingMeta | null;
    error: string | null;
    filters: Record<IssuesPage, IssuesFilters>;
    page: IssuesPage | null;
    showFiltersModal: boolean;
    showCreateIssueModal: boolean;
  };
  issueDetails: {
    id: string | null;
    issue: Issue | null;
    loading: boolean;
    error: string | null;
    questions: IssueQuestion[];
    audits: IConcise[];
    correctiveActions: IConcise[];
    events: {
      loading: boolean;
      error: string | null;
      meta: IPagingMeta | null;
      data: EntityState<IssueEvent, string>;
      lastUpdateDateTimeUtc: string | null;
    };
  };
  issueContext: {
    loading: boolean;
    error: string | null;
    data: IssueContext | null;
  };
  confirmDeleteModal: DeleteModalState<Issue>;
  reopenIssuesModal: null | string[];
  closeIssuesModal: null | string[];
  assignUsersToIssuesModal: AssignUsersToIssuesModalState;
}>(
  {
    issuesList: {
      ...issuesListInitialState,
    },
    issueDetails: {
      ...issueDetailsInitialState,
    },
    issueContext: {
      loading: false,
      error: null,
      data: null,
    },
    confirmDeleteModal: null,
    closeIssuesModal: null,
    reopenIssuesModal: null,
    assignUsersToIssuesModal: null,
  },
  (builder) =>
    builder
      .addCase(issuesActions.setPage, (state, { payload }) => {
        state.issuesList.page = payload;
      })
      .addCase(issuesActions.getIssues.pending, (state, { meta: { arg } }) => {
        state.issuesList.loading = true;
        state.issuesList.error = null;

        if (state.issuesList.page === IssuesPage.My && arg === null) {
          state.issuesList.data = issuesEntityAdapter.getInitialState();
        }

        if (
          state.issuesList.page !== null &&
          state.issuesList.filters !== null
        ) {
          state.issuesList.filters[state.issuesList.page] =
            arg === null
              ? {
                  ...getInitialIssuesFilters(),
                }
              : {
                  ...state.issuesList.filters[state.issuesList.page],
                  ...(arg || {}),
                };
        }
      })
      .addCase(
        issuesActions.getIssues.fulfilled,
        (state, { payload: { meta, data }, meta: { arg } }) => {
          state.issuesList.loading = false;

          if (state.issuesList.page === IssuesPage.My && arg !== null) {
            issuesEntityAdapter.addMany(state.issuesList.data, data);
          } else {
            state.issuesList.data = issuesEntityAdapter.setAll(
              state.issuesList.data,
              data
            );
          }

          state.issuesList.meta = meta;
        }
      )
      .addCase(issuesActions.getIssues.rejected, (state, { payload }) => {
        state.issuesList.loading = false;
        state.issuesList.error = payload || null;
      })
      .addCase(issuesActions.toggleFiltersModal, (state, { payload }) => {
        state.issuesList.showFiltersModal = payload;
      })
      .addCase(issuesActions.toggleCreateIssueModal, (state, { payload }) => {
        state.issuesList.showCreateIssueModal = payload;
      })
      .addCase(
        issuesActions.toggleConfirmDeleteIssuesModal,
        (state, { payload }) => {
          state.confirmDeleteModal = payload;
        }
      )
      .addCase(
        issuesActions.getIssueDetails.pending,
        (
          state,
          {
            meta: {
              arg: { showLoader },
            },
          }
        ) => {
          if (showLoader) {
            state.issueDetails.loading = true;
          }
          state.issueDetails.error = null;
        }
      )
      .addCase(
        issuesActions.getIssueDetails.fulfilled,
        (
          state,
          { payload: { questions, audits, correctiveActions, ...issue } }
        ) => {
          state.issueDetails.loading = false;
          state.issueDetails.questions = questions;
          state.issueDetails.audits = audits;
          state.issueDetails.correctiveActions = correctiveActions;
          state.issueDetails.issue = issue;
        }
      )
      .addCase(issuesActions.getIssueDetails.rejected, (state, { payload }) => {
        state.issueDetails.loading = false;
        state.issueDetails.error = payload || null;
      })
      .addCase(issuesActions.getIssueEvents.pending, (state) => {
        state.issueDetails.events.loading = true;
        state.issueDetails.events.error = null;
      })
      .addCase(issuesActions.getIssueEvents.fulfilled, (state, { payload }) => {
        state.issueDetails.events.loading = false;
        issueEventsEntityAdapter.setAll(
          state.issueDetails.events.data,
          payload
        );
        state.issueDetails.events.lastUpdateDateTimeUtc =
          dateUTC().toISOString();
      })
      .addCase(issuesActions.getIssueEvents.rejected, (state, { payload }) => {
        state.issueDetails.events.loading = false;
        state.issueDetails.events.error = payload || null;
      })
      .addCase(issuesActions.getNewIssueEvents.pending, (state) => {
        state.issueDetails.events.error = null;
      })
      .addCase(
        issuesActions.getNewIssueEvents.fulfilled,
        (state, { payload }) => {
          issueEventsEntityAdapter.setMany(
            state.issueDetails.events.data,
            payload
          );
          state.issueDetails.events.lastUpdateDateTimeUtc =
            dateUTC().toISOString();
        }
      )
      .addCase(
        issuesActions.getNewIssueEvents.rejected,
        (state, { payload }) => {
          state.issueDetails.events.error = payload || null;
        }
      )
      .addCase(
        issuesActions.editIssue.fulfilled,
        (
          state,
          { payload: { correctiveActions, audits, questions, ...issue } }
        ) => {
          if (state.issueDetails.issue?.id === issue.id) {
            state.issueDetails.issue = issue;
            state.issueDetails.audits = audits;
            state.issueDetails.questions = questions;
            state.issueDetails.correctiveActions = correctiveActions;
          }

          const issueInList = issuesEntityAdapter
            .getSelectors()
            .selectById(state.issuesList.data, issue.id);

          if (issueInList) {
            issuesEntityAdapter.updateOne(state.issuesList.data, {
              id: issue.id,
              changes: { ...issue },
            });
          } else if (
            state.issuesList.page === IssuesPage.My &&
            !issue.assignedUsers.some(
              (user) =>
                user.id === state.issuesList.filters[IssuesPage.My].assignedTo
            )
          ) {
            issuesEntityAdapter.addOne(state.issuesList.data, issue);
          }
        }
      )
      .addCase(issuesActions.toggleReopenIssuesModal, (state, { payload }) => {
        state.reopenIssuesModal = payload;
      })
      .addCase(issuesActions.toggleCloseIssuesModal, (state, { payload }) => {
        state.closeIssuesModal = payload;
      })
      .addCase(issuesActions.reopenIssues.fulfilled, (state, { payload }) => {
        if (
          payload.issuesIds.length === 1 &&
          state.issueDetails.issue?.id === payload.issuesIds[0]
        ) {
          state.issueDetails.issue.status = IssueStatus.Opened;
        }
      })
      .addCase(issuesActions.closeIssues.fulfilled, (state, { payload }) => {
        if (
          payload.issuesIds.length === 1 &&
          state.issueDetails.issue?.id === payload.issuesIds[0]
        ) {
          state.issueDetails.issue.status = IssueStatus.Closed;
        }

        if (state.issuesList.page === IssuesPage.My) {
          issuesEntityAdapter.removeMany(
            state.issuesList.data,
            payload.issuesIds
          );
        }
      })
      .addCase(
        issuesActions.createIssueCommentEvent.pending,
        (
          state,
          {
            meta: {
              arg: { event },
            },
          }
        ) => {
          issueEventsEntityAdapter.addOne(
            state.issueDetails.events.data,
            event
          );
        }
      )
      .addCase(issuesActions.getIssueContext.pending, (state) => {
        state.issueContext.loading = true;
        state.issueContext.error = null;
      })
      .addCase(
        issuesActions.getIssueContext.fulfilled,
        (state, { payload }) => {
          state.issueContext.loading = false;
          state.issueContext.data = payload;
        }
      )
      .addCase(issuesActions.getIssueContext.rejected, (state, { payload }) => {
        state.issueContext.loading = false;
        state.issueContext.error = payload || null;
      })
      .addCase(issuesActions.resetIssueDetailsData, (state) => {
        state.issueDetails = {
          ...issueDetailsInitialState,
        };
      })
      .addCase(
        issuesActions.toggleAssignUsersToIssuesModal,
        (state, { payload }) => {
          state.assignUsersToIssuesModal = payload;
        }
      )
      .addCase(issuesActions.resetListData, (state) => {
        state.issuesList = {
          ...state.issuesList,
          filters: {
            ...state.issuesList.filters,
          },
          loading: false,
          data: issuesEntityAdapter.getInitialState(),
          meta: null,
          error: null,
          showFiltersModal: false,
          showCreateIssueModal: false,
        };

        if (state.issuesList.page !== null) {
          state.issuesList.filters = {
            ...state.issuesList.filters,
            [state.issuesList.page]: {
              ...state.issuesList.filters[state.issuesList.page],
              pageNumber: 1,
            },
          };
        }
      })
      .addCase(issuesActions.toggleIssueDetailsModal, (state, { payload }) => {
        if (payload === null) {
          state.issueDetails = {
            ...issueDetailsInitialState,
          };
        } else {
          state.issueDetails.id = payload;
          state.issueDetails.loading = true;
        }
      })
      .addCase(issuesActions.deleteIssueLocally, (state, { payload }) => {
        issuesEntityAdapter.removeOne(state.issuesList.data, payload);
      })
      .addCase(issuesActions.updateIssueLocally, (state, { payload }) => {
        issuesEntityAdapter.updateOne(state.issuesList.data, payload);
      })
);
