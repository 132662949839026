import React from 'react';
import { X, AlertTriangle } from 'react-feather';

import {
  Container,
  Placeholder,
  ImageHolder,
  Image,
  RemoveFile,
} from './styled';
import { IApiService, IFile } from '@repo/shared/types';

import OfflineIcon from '@repo/shared/components/Previews/OfflineIcon';
import ImageContainer from '@repo/shared/components/Previews/ImageContainer';
import { colors } from '@repo/shared/config';

interface IProps {
  file: IFile;
  getFilePath: () => string;
  getMetadataPath?: (fileId: string) => string;
  onRemove?: () => void;
  onClick: (id: string) => void;
  forceFetch?: boolean;
  imagePreviewStyle?: React.CSSProperties;
  imagePreviewContainerStyle?: React.CSSProperties;
  apiService?: IApiService;
  skipCompanyId?: boolean;
}

const ImagePreview: React.FC<React.PropsWithChildren<IProps>> = ({
  file,
  getFilePath,
  getMetadataPath,
  onRemove,
  onClick,
  imagePreviewStyle,
  imagePreviewContainerStyle,
  apiService,
  skipCompanyId,
}) => {
  return (
    <ImageContainer
      fileId={file.id}
      getFilePath={getFilePath}
      getMetadataPath={getMetadataPath}
      apiService={apiService}
      skipCompanyId={skipCompanyId}
      onClick={() => {
        if (navigator.onLine) {
          onClick(file.id);
        }
      }}
      extra={
        onRemove && (
          <RemoveFile
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
            type="button"
          >
            <X />
          </RemoveFile>
        )
      }
    >
      {({ hasError, imageUrl, onLoadImage, onLoadImageError, isOffline }) => {
        return (
          <Container
            style={{
              width: imagePreviewStyle?.width,
              height: imagePreviewStyle?.height,
              ...(imagePreviewContainerStyle || {}),
            }}
          >
            {hasError ? (
              <Placeholder>
                <AlertTriangle color={colors.red} />
              </Placeholder>
            ) : (
              <>
                {isOffline ? (
                  <Placeholder>
                    <OfflineIcon />
                  </Placeholder>
                ) : (
                  <ImageHolder
                    isVideoPreview={file.contentType.startsWith('video')}
                  >
                    <Image
                      src={imageUrl}
                      style={imagePreviewStyle}
                      onLoad={onLoadImage}
                      onError={onLoadImageError}
                    />
                  </ImageHolder>
                )}
              </>
            )}
          </Container>
        )
      }}
    </ImageContainer>
  );
};

export default ImagePreview;
