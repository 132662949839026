import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import {
  IAccount,
  IAnyObject,
  IAppConfiguration,
  ICompany,
  ICompanySetupData,
  ICoupon,
  ICreateSubscriptionRequest,
  ICurrentUser,
  ICurrentUserPermissions,
  IFormsDigitizationRequest,
  IInvoice,
  IPagedResponse,
  IPermission,
  IPrices,
  ISignInParams,
  ISignUpParams,
  ISpotlightBounds,
  ISubscription,
  SampleDataOperation,
} from '@repo/shared/types';
import {
  broadcastAuthEvent,
  cleanUpSelfGuidedTourStepChanges,
  notification,
  resetBrowserTabId,
} from '@utils';
import { getErrorMessage, delay } from '@repo/shared/utils';
import { intl } from '@repo/shared/components/IntlGlobalProvider';
import {
  apiUrls,
  CURRENT_PASS_NOT_CORRECT_ERR,
  HAS_RESET_PASSWORD_TEMP,
  NO_ACCOUNTS_ERROR,
  REQUIRE_RECENT_LOGIN_ERR,
  routes,
  selfGuidedTourSteps,
} from '@config';
import TagManager from '../../../services/gtm';
import { AuditTemplateBuilderMode } from '@application/AuditTemplateBuilder/models/AuditTemplateBuilderState';
import { Logger } from '@repo/shared/services';
import {
  AnalyticsEvent,
  AuthEvent,
  BillingPeriod,
  FeedbackModalPage,
  LiveChatVisibility,
  Locale,
  Modal,
  PaymentStep,
  SelfGuidedTourStep,
  UsagePurpose,
} from '@repo/shared/enums';
import { IAppDispatch, IRootState } from '../../../frameworks/redux';
import * as UserPreferencesService from '@services/userPreferences.service';
import {
  accountSelectors,
  actionsActions,
  actionsSelectors,
  auditObjectsActions,
  generalActions,
} from '@store';
import { auditsSelectors } from '@application/Audits/store/auditsSelectors';
import { auditsActions } from '@application/Audits/store/auditsActions';
import { InternalApiService } from '@repo/shared/api';
import { accountEntityAdapter } from '@store/entityAdapters';
import { AuthService } from '@repo/shared/auth';
import { ApiConflictError } from '@repo/shared/errors';
import { schedulesActions } from '@application/Schedules/store/schedulesActions';
import { auditTemplatesSelectors } from '@application/AuditTemplates/store/auditTemplatesSelectors';
import { auditTemplateBuilderActions } from '@application/AuditTemplateBuilder/store/auditTemplateBuilderActions';
import { browserHistory } from '@src/browserHistory';

const apiService = InternalApiService.getInstance();

const resetStore = createAction('account/resetStore');
const updateCurrentUser = createAsyncThunk<
  Partial<ICurrentUser>,
  Partial<ICurrentUser>,
  { state: IRootState }
>('account/updateCurrentUser', async (update, { rejectWithValue }) => {
  try {
    await apiService.post({
      url: `${apiUrls.users}/myuser`,
      body: {
        ...update,
      },
    });

    return update;
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});
const updateCurrentUserLocally = createAction<Partial<ICurrentUser>>(
  'account/updateCurrentUserLocally'
);
const updateCompany = createAsyncThunk<
  Partial<ICompany>,
  {
    local?: boolean;
    update: Partial<ICompany>;
  },
  { rejectValue: string; state: IRootState }
>(
  'account/updateCompany',
  async ({ local, update }, { rejectWithValue, getState }) => {
    try {
      const company = getState().account.company;

      if (!local) {
        if (!company?.id) {
          throw new Error('Company ID has to be defined');
        }

        if (update.usagePurpose !== UsagePurpose.SomethingElse) {
          update.usagePurposeObjectName = null;
        }

        const body: IAnyObject = {
          ...update,
        };

        if (body.name) {
          body.companyName = update.name;
          delete body.name;
        }

        await apiService.put({
          url: `${apiUrls.accounts}/${company?.id}`,
          skipCompanyId: true,
          body,
        });
      }

      return update;
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);
const signOut = createAsyncThunk(
  'account/signOut',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const browserTabId = accountSelectors.getBrowserTabId(getState());
      await UserPreferencesService.deleteItem(HAS_RESET_PASSWORD_TEMP);
      apiService.resetCompanyId();
      await AuthService.signOut();
      resetBrowserTabId();
      Logger.removeUser();
      dispatch(resetStore());
      await dispatch(getAppConfiguration());
      dispatch(setBrowserTabId(browserTabId));
      await broadcastAuthEvent(AuthEvent.SignOut);
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);
const sendAnalyticsEvent = createAsyncThunk<
  void,
  AnalyticsEvent,
  { rejectValue: string; state: IRootState }
>(
  'account/sendAnalyticsEvent',
  async (event, { rejectWithValue, getState }) => {
    const state = getState();
    const authProvider = accountSelectors.getAuthProvider(state);
    const currentCompanyId = accountSelectors.getCurrentCompanyId(state);
    const authUserId = accountSelectors.getAuthUserId(state);

    try {
      await apiService.post({
        url: apiUrls.metrics,
        body: {
          eventType: event,
          userCookie: Cookies.get('_ga'),
        },
      });

      TagManager.sendEvent({
        event,
        userId: authUserId,
        authProvider,
        companyId: currentCompanyId,
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }
);
const getAccounts = createAsyncThunk<
  { accounts: IAccount[] },
  void,
  { rejectValue: string }
>('account/getAccounts', async (_, { rejectWithValue }) => {
  try {
    const accounts: IAccount[] = await apiService.get({
      url: apiUrls.accounts,
      skipCompanyId: true,
    });

    return {
      accounts,
    };
  } catch (e) {
    Logger.captureException(e);

    return rejectWithValue(getErrorMessage(e));
  }
});
const toggleFullScreenLoader = createAction<boolean>(
  'account/toggleFullScreenLoader'
);
const setCurrentCompanyId = createAction<string>('account/setCurrentCompanyId');
const getAppConfiguration = createAsyncThunk<
  IAppConfiguration | null,
  void,
  { state: IRootState }
>('account/getAppConfiguration', async (_, { rejectWithValue }) => {
  try {
    return apiService.get({
      url: 'configuration',
      skipCompanyId: true,
    });
  } catch (e) {
    Logger.captureException(e);
    return rejectWithValue(getErrorMessage(e));
  }
});
const setAuthFormLoading = createAction<boolean>('account/setAuthFormLoading');
const setBrowserTabId = createAction<string | undefined>(
  'account/setBrowserTabId'
);
const getAppData = createAsyncThunk<
  {
    locale: Locale;
    user: ICurrentUser;
    company: ICompany;
    authUserId: string;
  },
  void,
  { state: IRootState }
>('account/loadApp ', async (_, { rejectWithValue, dispatch, getState }) => {
  try {
    await apiService.post({
      url: `${apiUrls.accounts}/validate`,
      skipCompanyId: true,
    });

    const resultAction = await dispatch(getAccounts());

    const { accounts } = unwrapResult(resultAction);

    if (accounts.length === 0) {
      throw new Error(NO_ACCOUNTS_ERROR);
    }

    let currentCompanyId =
      await UserPreferencesService.getItem<string>('companyId');
    if (
      !currentCompanyId ||
      (currentCompanyId &&
        !accounts.find((account) => account.company?.id === currentCompanyId))
    ) {
      currentCompanyId = accounts[0].company.id;
      await UserPreferencesService.setItem('companyId', currentCompanyId);
    }
    InternalApiService.configure(currentCompanyId);
    dispatch(setCurrentCompanyId(currentCompanyId));

    const [user] = await Promise.all([
      apiService.get<any>({
        url: `${apiUrls.users}/myuser`,
      }),
    ]);

    let { locale, company, role, ...currentUser } = user;

    if (!locale) {
      const browserLocale = navigator.language?.split('-')?.[0];
      locale = browserLocale in Locale ? (browserLocale as Locale) : Locale.en;
    }

    const appConfiguration = accountSelectors.getAppConfiguration(getState());
    if (currentUser.showFeedbackModal && appConfiguration.allowFeedbackModal) {
      dispatch(
        generalActions.feedbackModal.setPage(FeedbackModalPage.LeaveFeedback)
      );
    }

    dispatch(generalActions.getConciseData());

    Logger.setUser({
      id: currentUser.id,
      email: currentUser.email,
      companyId: company.id,
      companyName: company.name,
    });

    const authUserId = await AuthService.getAuthUserId();

    if (!authUserId) {
      throw new Error('getAppData action: authUserId is undefined');
    }

    return {
      user: {
        ...currentUser,
        role: {
          ...role,
          permissions: (
            role.permissions as IPermission[]
          ).reduce<ICurrentUserPermissions>((acc, permission) => {
            if (permission.isEnabled) {
              acc[permission.rolePermissionType] = true;
            }
            return acc;
          }, {} as ICurrentUserPermissions),
        },
      },
      company,
      locale,
      authUserId,
    };
  } catch (e: any) {
    if (e.message === NO_ACCOUNTS_ERROR) {
      browserHistory.push(routes.signup + browserHistory.location.search, {
        toCompanySetup: true,
      });
      return rejectWithValue('');
    }

    if (e instanceof ApiConflictError && e.message === 'auth/user-not-found') {
      notification.error({
        message: intl.formatMessage({ id: 'UsernameNotFound' }),
        description: intl.formatMessage({
          id: 'auth/user-not-found',
        }),
      });
    } else {
      notification.error({
        message: intl.formatMessage({ id: 'Error' }),
        description: intl.formatMessage({
          id: 'ErrorWhileLoadingApplicationData',
        }),
      });
    }

    await AuthService.signOut();

    if (accountSelectors.getAuthFormLoading(getState())) {
      dispatch(setAuthFormLoading(false));
    }

    Logger.captureException(e);

    return rejectWithValue('');
  }
});
const setSelfGuidedTourStep = createAsyncThunk<
  SelfGuidedTourStep | null,
  SelfGuidedTourStep | null,
  { rejectValue: { nextStep: SelfGuidedTourStep }; state: IRootState }
>(
  'account/selfGuidedTour/setSelfGuidedTourStep',
  async (desiredStepIndex, { rejectWithValue, getState, dispatch }) => {
    const currentStepIndex = getState().account.selfGuidedTour
      .step as SelfGuidedTourStep | null;

    if (currentStepIndex === null) {
      return null;
    }

    const userPermissions = accountSelectors.getPermissionsMap(getState());
    const currentStep = selfGuidedTourSteps[currentStepIndex];
    let nextStepIndex =
      desiredStepIndex !== null ? desiredStepIndex : currentStep.nextStep;

    if (nextStepIndex === null) {
      return null;
    }

    let nextStep = selfGuidedTourSteps[nextStepIndex];

    if (
      nextStep.requiredPermissions !== null &&
      !nextStep.requiredPermissions.every(
        (permission) => userPermissions[permission]
      )
    ) {
      while (
        nextStepIndex !== null &&
        nextStep.requiredPermissions !== null &&
        !nextStep.requiredPermissions.every(
          (permission) => userPermissions[permission]
        )
      ) {
        nextStepIndex = selfGuidedTourSteps[nextStepIndex].nextStep;

        if (nextStepIndex !== null) {
          nextStep = selfGuidedTourSteps[nextStepIndex];
        }
      }
    }

    if (
      nextStep.route &&
      currentStep.route !== nextStep.route &&
      !nextStep.route.includes(':id')
    ) {
      browserHistory.replace(nextStep.route);
      await delay(1000);
    }

    switch (nextStepIndex) {
      case SelfGuidedTourStep.AddObjectForm:
        dispatch(auditObjectsActions.objects.showAddEditModal());
        await delay(1000);
        break;
      case SelfGuidedTourStep.AddUserFormNameEmail:
        dispatch(generalActions.showModal({ name: Modal.AddEdit }));
        await delay(1000);
        break;
      case SelfGuidedTourStep.AddScheduleForm:
        dispatch(schedulesActions.toggleAddEditModal({ opened: true }));
        await delay(1000);
        break;
      case SelfGuidedTourStep.BuilderTemplateSetup: {
        const { data } = auditTemplatesSelectors.getTemplatesGroups(getState());

        if (data.length === 0 || data[0].versions.length === 0) {
          return rejectWithValue({ nextStep: SelfGuidedTourStep.AddSchedule });
        }

        const { id, isDraft } = data[0].versions[0];

        const route = isDraft ? routes.builderDraft : routes.builderPublished;

        browserHistory.push(route.replace(':id', id));

        await delay(500);

        break;
      }
      case SelfGuidedTourStep.BuilderTemplatePreview:
        dispatch(
          auditTemplateBuilderActions.changeBuilderMode(
            AuditTemplateBuilderMode.Preview
          )
        );
        await delay(500);
        break;
      case SelfGuidedTourStep.ViewAuditsReports: {
        const { data } = auditsSelectors.getAudits(getState());

        if (data.length === 0) {
          return rejectWithValue({
            nextStep: SelfGuidedTourStep.SummaryDashboard,
          });
        }

        browserHistory.push(routes.completedAudit.replace(':id', data[0].id));

        break;
      }
      case SelfGuidedTourStep.AuditReport: {
        const { data } = auditsSelectors.getAudits(getState());

        if (data.length === 0) {
          return rejectWithValue({
            nextStep: SelfGuidedTourStep.SummaryDashboard,
          });
        }

        dispatch(
          auditsActions.toggleAuditReportModal({ opened: true, audit: data[0] })
        );

        break;
      }
      case SelfGuidedTourStep.ActionDetails: {
        const { data } = actionsSelectors.getActions(getState());

        if (data.length === 0) {
          return rejectWithValue({
            nextStep: SelfGuidedTourStep.AuditPerformanceWidget,
          });
        }

        dispatch(actionsActions.toggleActionDetailsModal(data[0].id));

        break;
      }
      default:
        break;
    }

    return nextStepIndex;
  }
);

export const accountActions = {
  getAppData,
  getAccounts,
  getAppConfiguration,
  loadApp: createAsyncThunk('account/loadApp', async (_, { dispatch }) => {
    dispatch(toggleFullScreenLoader(true));

    dispatch(setBrowserTabId());

    await dispatch(getAppConfiguration());

    let isAuthenticated = false;

    try {
      isAuthenticated = await AuthService.isAuthenticated();
    } catch (e) {
      Logger.captureException(e);
    }

    if (isAuthenticated) {
      await dispatch(getAppData());
    }

    dispatch(toggleFullScreenLoader(false));
  }),
  toggleFullScreenLoader,
  setCurrentCompanyId,
  resetStore,
  updateCurrentUserLocally,
  updateCompany,
  setDefaultTemplateId: createAction<string>('account/setDefaultTemplateId'),
  reset: createAsyncThunk(
    'account/reset',
    async (_, { rejectWithValue, dispatch }) => {
      try {
        dispatch(resetStore());
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  updateAccount: createAsyncThunk<
    {
      accountId: string;
      update: Partial<IAccount>;
    },
    {
      accountId: string;
      localOnly?: boolean;
      update: Partial<IAccount>;
    },
    { rejectValue: string; state: IRootState }
  >(
    'account/updateAccount',
    async ({ accountId, update, localOnly }, { rejectWithValue, getState }) => {
      const state = getState();

      try {
        const account = accountEntityAdapter
          .getSelectors()
          .selectById(state.account.accounts.data, accountId);

        if (!localOnly) {
          await apiService.put({
            url: `${apiUrls.accounts}/${account?.company?.id}`,
            skipCompanyId: true,
            body: {
              ...update,
            },
          });
        }

        return {
          accountId,
          update,
        };
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  deleteCompany: createAsyncThunk<void, string, { rejectValue: string }>(
    'account/deleteCompany',
    async (companyId, { rejectWithValue }) => {
      try {
        await apiService.delete({
          url: `${apiUrls.accounts}/${companyId}`,
          skipCompanyId: true,
        });
      } catch (e) {
        Logger.captureException(e);

        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  signIn: createAsyncThunk<void, ISignInParams, { rejectValue: string }>(
    'account/signIn',
    async ({ email, password }, { rejectWithValue, dispatch }) => {
      try {
        await AuthService.signInWithPassword(email, password);
        await dispatch(accountActions.loadApp());
        await broadcastAuthEvent(AuthEvent.SignIn);
      } catch (e) {
        Logger.captureException(e);

        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  changePassword: createAsyncThunk<
    void,
    {
      password: string;
      currentPassword?: string;
      email: string;
    },
    { rejectValue: string }
  >(
    'account/changePassword',
    async ({ currentPassword, password, email }, { rejectWithValue }) => {
      try {
        await AuthService.changePassword({ currentPassword, password, email });
      } catch (e: any) {
        if (e.message === 'auth/wrong-password') {
          return rejectWithValue(CURRENT_PASS_NOT_CORRECT_ERR);
        }

        if (e.message === 'auth/requires-recent-login') {
          return rejectWithValue(REQUIRE_RECENT_LOGIN_ERR);
        }

        Logger.captureException(e);

        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  signInWithCustomToken: createAsyncThunk<
    void,
    ISignInParams,
    { rejectValue: string }
  >(
    'account/signInWithCustomToken',
    async (payload, { rejectWithValue, dispatch }) => {
      try {
        const { token } = await apiService.post<{ token: string }>({
          url: apiUrls.adminSignIn,
          body: payload,
          host: import.meta.env.VITE_FUNCTIONS_BASE_URL,
        });

        await AuthService.signInWithToken(token);
        await dispatch(accountActions.loadApp());
        await broadcastAuthEvent(AuthEvent.SignIn);
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  signInWithGoogle: createAsyncThunk<void, void, { rejectValue: string }>(
    'account/signInWithProvider',
    async (_, { dispatch, rejectWithValue }) => {
      try {
        await AuthService.signInWithGoogle();
        await dispatch(accountActions.loadApp());
        await broadcastAuthEvent(AuthEvent.SignIn);
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  signUp: createAsyncThunk<
    void,
    ISignUpParams,
    { rejectValue: string | { profileAlreadyExists: boolean } }
  >(
    'account/signUp',
    async ({ email, password }, { rejectWithValue, dispatch }) => {
      try {
        await apiService.post({
          url: apiUrls.profile,
          body: {
            email,
            password,
          },
        });

        await AuthService.signInWithPassword(email, password);
        await dispatch(accountActions.loadApp());
        await broadcastAuthEvent(AuthEvent.SignIn);
      } catch (e: any) {
        Logger.captureException(e);

        if (e.message === 'auth/account-already-exists') {
          return rejectWithValue({
            profileAlreadyExists: true,
          });
        }

        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  createCompany: createAsyncThunk<
    { companyId: string },
    ICompanySetupData,
    { state: IRootState }
  >(
    'account/setup/createAccount',
    async (
      {
        admin,
        name,
        companySize,
        industry,
        usagePurposeObjectName,
        usagePurpose,
        defaultIanaTimeZone,
        templateId,
      },
      { getState, rejectWithValue, dispatch }
    ) => {
      try {
        const state = getState();
        const locale = accountSelectors.getLocale(state);

        const { companyId } = await apiService.post<{
          companyId: string;
        }>({
          url: apiUrls.accounts,
          body: {
            companyAdmin: {
              fullName: admin.fullName,
              phone: admin.phone,
              locale,
            },
            companyName: name,
            companySize,
            industry,
            usagePurpose,
            usagePurposeObjectName,
            defaultIanaTimeZone,
            templateId,
          },
          skipCompanyId: true,
        });

        await AuthService.getAuthToken(true);

        dispatch(setCurrentCompanyId(companyId));

        return {
          companyId,
        };
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  toggleSetupCompanyModal: createAction<boolean>(
    'account/toggleSetupCompanyModal'
  ),
  sendPasswordResetEmail: createAsyncThunk<
    void,
    string,
    { rejectValue: string }
  >('account/sendPasswordResetEmail', async (email, { rejectWithValue }) => {
    try {
      await apiService.post({
        url: `${apiUrls.accounts}/send-reset-password`,
        skipCompanyId: true,
        body: {
          email,
        },
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }),
  validateResetPasswordLink: createAsyncThunk<
    void,
    { token: string; userAuthId: string },
    { rejectValue: string }
  >(
    'account/setNewPassword',
    async ({ token, userAuthId }, { rejectWithValue }) => {
      try {
        await apiService.get({
          url: `${apiUrls.accounts}/validate-reset-password-link/${userAuthId}`,
          skipCompanyId: true,
          query: {
            token,
          },
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  setNewPassword: createAsyncThunk<
    void,
    { password: string; token: string; userAuthId: string },
    { rejectValue: string }
  >(
    'account/setNewPassword',
    async ({ password, token, userAuthId }, { rejectWithValue }) => {
      try {
        await apiService.post({
          url: `${apiUrls.accounts}/reset-password/${userAuthId}`,
          skipCompanyId: true,
          body: {
            password,
            token,
          },
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  setResetPassword: createAsyncThunk<void, void, { rejectValue: string }>(
    'account/setResetPassword',
    async (_, { rejectWithValue }) => {
      try {
        await apiService.post({
          url: `${apiUrls.profile}/set-reset-password`,
          skipCompanyId: true,
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  confirmPasswordReset: createAsyncThunk<
    void,
    { code: string; password: string },
    { rejectValue: string }
  >(
    'account/confirmPasswordReset',
    async ({ code, password }, { rejectWithValue }) => {
      try {
        await AuthService.confirmPasswordReset(code, password);
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  updateCurrentUser,
  changeLocale: createAsyncThunk<
    Locale,
    Locale,
    { rejectValue: string; state: IRootState }
  >(
    'account/changeLocale',
    async (locale, { rejectWithValue, getState, dispatch }) => {
      try {
        const { user } = getState().account;

        if (user) {
          await dispatch(updateCurrentUser({ locale }));
        }

        return locale;
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  signOut,
  setPaymentStep: createAction<PaymentStep>('account/billing/setPaymentStep'),
  setBillingPeriod: createAction<BillingPeriod>(
    'account/billing/setBillingPeriod'
  ),
  getPrices: createAsyncThunk<
    IPrices,
    undefined,
    { rejectValue: string; state: IRootState }
  >('account/billing/getPrices', async (_, { rejectWithValue }) => {
    try {
      return apiService.get({
        url: apiUrls.billing.price,
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }),
  setAuthFormLoading,
  getSubscription: createAsyncThunk<
    ISubscription,
    undefined,
    { rejectValue: string; state: IRootState }
  >('account/billing/getSubscription', async (_, { rejectWithValue }) => {
    try {
      return apiService.get({
        url: apiUrls.billing.subscription,
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }),
  validateCouponCode: createAsyncThunk<
    ICoupon,
    string,
    { rejectValue: string }
  >('account/validateCouponCode', async (couponCode, { rejectWithValue }) => {
    try {
      return await apiService.get({
        url: apiUrls.billing.validateCouponCode,
        query: {
          code: couponCode,
        },
      });
    } catch (e) {
      Logger.captureException(e);

      return rejectWithValue(getErrorMessage(e));
    }
  }),
  removeCouponLocally: createAction('account/removeCouponLocally'),
  getInvoices: createAsyncThunk<
    IPagedResponse<IInvoice>,
    void,
    { rejectValue: string; state: IRootState }
  >('account/billing/getInvoices', async (_, { rejectWithValue }) => {
    try {
      return apiService.get({
        url: apiUrls.billing.invoices,
      });
    } catch (e) {
      Logger.captureException(e);
      return rejectWithValue(getErrorMessage(e));
    }
  }),
  createSubscription: createAsyncThunk<
    ICreateSubscriptionRequest,
    ICreateSubscriptionRequest,
    { rejectValue: string; state: IRootState }
  >(
    'account/billing/createSubscription',
    async (payload, { rejectWithValue }) => {
      try {
        await apiService.post({
          url: apiUrls.billing.subscription,
          body: payload,
        });

        return payload;
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  sendAnalyticsEvent,
  toggleConfirmDeleteAccountModal: createAction<{
    show: boolean;
    company?: { id: string; name: string };
  }>('account/toggleConfirmDeleteAccountModal'),
  toggleSampleDataModal: createAction<{
    visible: boolean;
    operation?: SampleDataOperation;
  }>('account/toggleSampleDataModal'),
  deleteSampleData: createAsyncThunk<void, void, { rejectValue: string }>(
    'account/deleteSampleData',
    async (_, { rejectWithValue, dispatch }) => {
      try {
        await apiService.delete({
          url: 'sample-data',
        });

        dispatch(generalActions.resetAccountData());
      } catch (e) {
        Logger.captureException(e);

        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  addSampleData: createAsyncThunk<void, void, { rejectValue: string }>(
    'account/addSampleData',
    async (_, { rejectWithValue }) => {
      try {
        await apiService.post({
          url: 'sample-data',
        });
      } catch (e) {
        Logger.captureException(e);

        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  changeAccount: createAsyncThunk<
    void,
    { companyId: string; fromCompanySetup?: boolean },
    { rejectValue: string }
  >(
    'account/changeAccount',
    async ({ companyId, fromCompanySetup }, { dispatch }) => {
      dispatch(accountActions.toggleFullScreenLoader(true));

      await UserPreferencesService.setItem('companyId', companyId);

      dispatch(generalActions.resetAccountData());

      browserHistory.push(routes.dashboard, { fromCompanySetup });

      await dispatch(getAppData());

      dispatch(accountActions.toggleFullScreenLoader(false));
    }
  ),
  toggleWelcomeModal: createAction<boolean>('account/toggleWelcomeModal'),
  toggleDownloadAppsModal: createAction<boolean>(
    'account/toggleDownloadAppsModal'
  ),
  selfGuidedTour: {
    start: createAsyncThunk<void, void>(
      'account/selfGuidedTour/start',
      async (_, { dispatch }) => {
        browserHistory.push(routes.dashboard);
        dispatch(sendAnalyticsEvent(AnalyticsEvent.SelfGuidedTourClicked));
      }
    ),
    nextStep: createAsyncThunk<
      SelfGuidedTourStep | null,
      void,
      { rejectValue: string; state: IRootState }
    >('account/selfGuidedTour/nextStep', async (_, { getState, dispatch }) => {
      const currentStepIndex = getState().account.selfGuidedTour
        .step as SelfGuidedTourStep | null;

      cleanUpSelfGuidedTourStepChanges(
        dispatch as IAppDispatch,
        currentStepIndex
      );

      let result = await dispatch(setSelfGuidedTourStep(null));

      while (
        setSelfGuidedTourStep.rejected.match(result) &&
        result.payload !== undefined
      ) {
        result = await dispatch(setSelfGuidedTourStep(result.payload.nextStep));
      }

      return unwrapResult(result);
    }),
    stop: createAsyncThunk<void, void, { state: IRootState }>(
      'account/selfGuidedTour/stop',
      async (_, { dispatch, getState }) => {
        const currentStepIndex = getState().account.selfGuidedTour
          .step as SelfGuidedTourStep | null;

        cleanUpSelfGuidedTourStepChanges(
          dispatch as IAppDispatch,
          currentStepIndex
        );

        browserHistory.push(routes.dashboard);
      }
    ),
    setSelfGuidedTourStep,
    setSpotlightBounds: createAction<ISpotlightBounds | null>(
      'account/selfGuidedTour/setSpotlightsBounds'
    ),
  },
  setLiveChatVisibility: createAction<LiveChatVisibility>(
    'account/setLiveChatVisibility'
  ),
  toggleFormsDigitizationModal: createAction<boolean>(
    'account/toggleFormsDigitizationModal'
  ),
  sendDigitizeFormsRequest: createAsyncThunk<
    void,
    IFormsDigitizationRequest,
    { state: IRootState }
  >(
    'account/sendDigitizeFormsRequest',
    async (request, { rejectWithValue }) => {
      try {
        await apiService.post({
          url: `${apiUrls.accounts}/forms-digitization-request`,
          body: {
            ...request,
          },
        });
      } catch (e) {
        Logger.captureException(e);
        return rejectWithValue(getErrorMessage(e));
      }
    }
  ),
  setLocale: createAction<Locale>('account/setLocale'),
  setCompany: createAction<ICompany>('account/setCompany'),
  setBrowserTabId,
  toggleNewVersionReleasedModal: createAction<boolean>(
    'account/newVersionReleasedModal'
  ),
};
