import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import Input from 'antd/es/input';

import { notification } from '@utils';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { useAppDispatch } from '@hooks';
import { generalActions, generalSelectors } from '@store';

import Modal from '@components/shared/ant/Modal/Modal';
import Form from '@components/shared/ant/Form';
import { IssuesPage } from '@application/Issues/enums/IssuesPage';

interface IProps {}

interface FormValues {
  comment: string;
}

const ReopenIssuesModal: React.FC<React.PropsWithChildren<IProps>> = () => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<FormValues>();
  const dispatch = useAppDispatch();

  const [processing, setProcessing] = useState(false);

  const issuesIds = useSelector(issuesSelectors.getReopenIssuesModalState);
  const selectedTableRowKeys = useSelector(
    generalSelectors.getSelectedTableRowsKeys
  );
  const page = useSelector(issuesSelectors.getPage);

  const opened = Array.isArray(issuesIds);

  const closeModal = useCallback(() => {
    form.resetFields();
    dispatch(issuesActions.toggleReopenIssuesModal(null));
  }, []);

  return (
    <Modal
      title={
        opened
          ? formatMessage(
              {
                id: 'ReopenIssues',
              },
              {
                count: issuesIds.length,
              }
            )
          : null
      }
      destroyOnClose
      open={opened}
      onOk={async () => {
        if (!opened) {
          return;
        }

        try {
          const { comment } = await form.validateFields();

          setProcessing(true);

          const resultAction = await dispatch(
            issuesActions.reopenIssues({
              issuesIds,
              comment,
            })
          );

          if (issuesActions.reopenIssues.fulfilled.match(resultAction)) {
            notification.success({
              message: formatMessage(
                {
                  id: 'IssuesReopened',
                },
                { count: issuesIds.length }
              ),
            });

            closeModal();

            if (selectedTableRowKeys.length > 0) {
              dispatch(generalActions.selectTableRows([]));
            }

            if (page === IssuesPage.My) {
              dispatch(issuesActions.getIssues(null));
            } else {
              dispatch(issuesActions.getIssues());
            }
          } else {
            notification.error({
              message: formatMessage(
                { id: 'ErrorWhileReopeningIssues' },
                { count: issuesIds.length }
              ),
            });
          }

          setProcessing(false);
        } catch (_) {}
      }}
      okText={formatMessage({
        id: 'Reopen',
      })}
      confirmLoading={processing}
      width={450}
      onCancel={() => {
        closeModal();
      }}
      closeIcon={<X />}
      styles={{
        body: {
          marginTop: '15px',
          marginBottom: '20px',
        },
      }}
    >
      <Form<FormValues>
        form={form}
        layout="vertical"
        initialValues={{ comment: '', reason: null }}
      >
        <Form.Item
          name="comment"
          label={formatMessage({ id: 'CommentOptional' })}
        >
          <Input.TextArea
            rows={5}
            disabled={processing}
            placeholder={formatMessage({ id: 'TypeSomethingHere' })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReopenIssuesModal;
