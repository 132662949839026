import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Skeleton from 'antd/es/skeleton';
import Spin from 'antd/es/spin';
import isEqual from 'lodash/isEqual';

import { AssigneesList, AssigneesListLabel, Container } from './styled';
import { issuesActions, issuesSelectors } from '@application/Issues/store';
import { date, dateInTimeZone, notification } from '@utils';
import { AssigneesModalContext, Permission } from '@repo/shared/enums';
import { useAppDispatch, usePermission } from '@hooks';
import { IssueDetails } from '@domain/Issues/models/Issue';
import { Dayjs } from 'dayjs';
import { config } from '@repo/shared/config';

import Form from '@components/shared/ant/Form';
import PrioritySelect from '@components/shared/PrioritySelect';
import DatePicker from '@components/shared/ant/DatePicker/DatePicker';
import IssueQuestionFormItem from '@src/presentation/Issues/IssueQuestionsForm/IssueTypeFieldFormItem/IssueQuestionFormItem';
import Assignees from '@components/shared/Assignees/Assignees';
import IssueLinkedItems from '@src/presentation/Issues/IssueDetails/IssueDetailsForm/LinkedEntities/IssueLinkedItems';

interface IProps {}

const IssueDetailsForm: React.FC<React.PropsWithChildren<IProps>> = () => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const [processing, setProcessing] = useState(false);

  const loading = useSelector(issuesSelectors.getIssueDetailsLoading);
  const error = useSelector(issuesSelectors.getIssueDetailsError);
  const issueQuestions = useSelector(issuesSelectors.getIssueDetailsQuestions);
  const issueAudits = useSelector(issuesSelectors.getIssueDetailsAudits);
  const issueActions = useSelector(issuesSelectors.getIssueDetailsActions);
  const issue = useSelector(issuesSelectors.getDetailsIssue);

  const [canEditIssues] = usePermission([Permission.CanEditIssues]);

  const updateIssue = useCallback(
    (update: Partial<IssueDetails>) => {
      async function _updateIssue(update: Partial<IssueDetails>) {
        if (!issue) {
          return;
        }

        setProcessing(true);

        const resultAction = await dispatch(
          issuesActions.editIssue({
            ...issue,
            correctiveActions: issueActions,
            audits: issueAudits,
            questions: issueQuestions,
            ...update,
          })
        );

        if (issuesActions.editIssue.rejected.match(resultAction)) {
          notification.error({
            message: formatMessage({ id: 'ErrorWhileEditingIssue' }),
          });
        } else {
          notification.success({
            message: formatMessage({ id: 'IssueHasBeenUpdated' }),
          });
        }

        setProcessing(false);
      }

      _updateIssue(update);
    },
    [
      JSON.stringify(issue || {}),
      JSON.stringify(issueAudits),
      JSON.stringify(issueActions),
      JSON.stringify(issueQuestions),
    ]
  );

  useEffect(() => {
    if (!loading && !error) {
      form.setFieldsValue({
        questions: issueQuestions,
        priority: issue?.priority,
        dueDate: date(issue?.dueDate.localTime),
      });
    }
  }, [loading, error, JSON.stringify(issue || null)]);

  return (
    <Spin spinning={processing}>
      <Form form={form} layout="vertical">
        {loading ? (
          <Container>
            <Skeleton active />
          </Container>
        ) : (
          <>
            {error ? (
              <Container>{error}</Container>
            ) : (
              <Container>
                {!!issue && (
                  <AssigneesList>
                    <AssigneesListLabel>
                      {formatMessage({ id: 'AssignTo' })}
                    </AssigneesListLabel>
                    <Assignees
                      users={issue?.assignedUsers}
                      auditObjectId={issue?.auditObject.id}
                      context={AssigneesModalContext.Issue}
                      disabled={!canEditIssues}
                      onChange={async (users) => {
                        updateIssue({
                          assignedUsers: users,
                        });
                      }}
                    />
                  </AssigneesList>
                )}
                <Form.Item
                  name="priority"
                  label={formatMessage({ id: 'Priority' })}
                >
                  <PrioritySelect
                    disabled={!canEditIssues}
                    onChange={(priority) => {
                      if (priority === issue?.priority) {
                        return;
                      }

                      updateIssue({
                        priority,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="dueDate"
                  label={formatMessage({ id: 'DueDate' })}
                >
                  <DatePicker
                    disabled={!canEditIssues}
                    disabledDate={(currentDate: Dayjs) =>
                      issue
                        ? currentDate.isBefore(
                            dateInTimeZone(
                              issue.createDate.localTime,
                              issue?.ianaTimeZone
                            )
                          )
                        : false
                    }
                    onChange={(date: Dayjs) => {
                      if (
                        !issue ||
                        issue.dueDate.localTime ===
                          date.format(config.apiDateFormat)
                      ) {
                        return;
                      }

                      updateIssue({
                        dueDate: {
                          ...issue.dueDate,
                          localTime: date.format(config.apiDateFormat),
                        },
                      });
                    }}
                  />
                </Form.Item>
                <Form.List name="questions">
                  {(fields) => (
                    <>
                      {fields.map((field, index) => (
                        <IssueQuestionFormItem
                          key={field.key}
                          index={index}
                          disabled
                          question={form.getFieldValue(['questions', index])}
                          onChange={(updatedQuestion) => {
                            if (
                              !issue ||
                              isEqual(updatedQuestion, issueQuestions[index])
                            ) {
                              return;
                            }

                            updateIssue({
                              questions: issueQuestions.map((question) =>
                                updatedQuestion.id === question.id
                                  ? updatedQuestion
                                  : question
                              ),
                            });
                          }}
                        />
                      ))}
                    </>
                  )}
                </Form.List>
                <IssueLinkedItems updateIssue={updateIssue} />
              </Container>
            )}
          </>
        )}
      </Form>
    </Spin>
  );
};

export default IssueDetailsForm;
